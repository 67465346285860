import { RouteName } from '@/constants/RouteName';
import { UserPermission } from '@/constants/UserPermission';
import { BofAAppMain } from '@/layout/components/index';
import { RouteConfig } from 'vue-router';

// TODO: WAA-10104 Apply basket list page to bofa router
export const adminChildrenRouter: RouteConfig[] = [
  {
    name: RouteName.SESSION_MANAGEMENT,
    path: 'users/sessions',
    components: {
      default: () => import('@/views/admin/users/AdminSessions.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Sessions',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.ACCOUNT_MANAGEMENT,
    path: 'users',
    components: {
      default: () => import('@/views/admin/users/AdminUsers.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Users',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.EDIT_USER,
    path: 'users/:id',
    components: {
      default: () => import('@/views/admin/users/AdminUserEdit.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Edit User',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.TRACK_MANAGEMENT,
    path: 'strategies',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/strategies/AdminTrackManagement.vue'),
      default: () => import('@/views/admin/strategies/AdminStrategies.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Strategies',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.BASKET_REQUEST,
    path: 'basket-request',
    components: {
      default: () => import('@/views/admin/AdminBasketRequestManagement.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Basket',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.PLATFORM_ANALYTICS,
    path: 'platform-analytics',
    components: {
      default: () => import('@/views/admin/AdminPlatformAnalytics.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Platform Analytics',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.TRACK_SYNC_JOBS,
    path: 'strategies/jobs',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/strategies/AdminTrackManagement.vue'),
      default: () => import('@/views/admin/jobs/AdminTrackSyncJobs.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Track Sync Jobs',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.TSE_PROVIDERS,
    path: 'strategies/providers',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/strategies/AdminTrackManagement.vue'),
      default: () => import('@/views/admin/strategies/AdminProviders.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Providers',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.CREATE_STRATEGY,
    path: 'strategies/new',
    components: {
      default: () => import('@/views/admin/strategies/AdminStrategyCreate.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Create Strategy',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.EDIT_STRATEGY,
    path: 'strategies/:id',
    components: {
      default: () => import('@/views/admin/strategies/AdminStrategyEdit.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Edit Strategy',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.PRIVATE_TRACKS,
    path: 'private-tracks',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/strategies/AdminTrackManagement.vue'),
      default: () => import('@/views/admin/private-tracks/AdminPrivateTracks.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Private Tracks',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.VIEW_PRIVATE_TRACK,
    path: 'private-tracks/:id',
    components: {
      default: () => import('@/views/admin/private-tracks/AdminPrivateTrackView.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Private Tracks',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },

  {
    name: RouteName.INSTRUMENTS_MANAGEMENT,
    path: 'instruments',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/instruments/AdminInstruments.vue'),
      default: () => import('@/views/admin/instruments/AdminInstrumentsMetrics.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Metrics',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.FUTURES,
    path: 'instruments/futures',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/instruments/AdminInstruments.vue'),
      default: () => import('@/views/admin/instruments/AdminInstrumentsFuturesTable.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Futures',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.STOCKS,
    path: 'instruments/stocks',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/instruments/AdminInstruments.vue'),
      default: () => import('@/views/admin/instruments/AdminInstrumentsStocksTable.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Stocks',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.BONDS,
    path: 'instruments/bonds',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/instruments/AdminInstruments.vue'),
      default: () => import('@/views/admin/instruments/AdminInstrumentsBondsTable.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Bonds',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.FUNDS,
    path: 'instruments/funds',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/instruments/AdminInstruments.vue'),
      default: () => import('@/views/admin/instruments/AdminInstrumentsFundsTable.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Funds',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.ETFS,
    path: 'instruments/etfs',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/instruments/AdminInstruments.vue'),
      default: () => import('@/views/admin/instruments/AdminInstrumentsETFsTable.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | ETFs',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.INDICES,
    path: 'instruments/indices',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/instruments/AdminInstruments.vue'),
      default: () => import('@/views/admin/instruments/AdminInstrumentsIndices.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Indices',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.MARKET_INDICES,
    path: 'instruments/market-indices',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/instruments/AdminInstruments.vue'),
      default: () => import('@/views/admin/instruments/AdminInstrumentsMarketIndices.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Market Indices',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.CDS_INDICES,
    path: 'instruments/cds-indices',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/instruments/AdminInstruments.vue'),
      default: () => import('@/views/admin/instruments/AdminInstrumentsCdsIndicesTable.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | CDS Indices',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.VIEW_CDS_INDEX,
    path: 'instruments/cds/:familyId',
    components: {
      default: () => import('@/views/admin/instruments/AdminCdsIndices.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Instruments',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.CUSTOM_INDICES,
    path: 'instruments/custom-indices',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/instruments/AdminInstruments.vue'),
      default: () => import('@/views/admin/instruments/AdminInstrumentsCustomIndicesTable.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Custom Indices',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.VIEW_CUSTOM_INDEX,
    path: 'instruments/custom/:securityId',
    components: {
      default: () => import('@/views/admin/instruments/AdminCustomIndex.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Instruments',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  ...[
    { name: RouteName.VIEW_INSTRUMENT, path: 'instruments/:securityId' },
    { name: RouteName.VIEW_INSTRUMENT_POSITION, path: 'instruments/:securityId/position' },
    { name: RouteName.VIEW_INSTRUMENT_RISK, path: 'instruments/:securityId/risk' },
    { name: RouteName.VIEW_INSTRUMENT_OPTIONS, path: 'instruments/:securityId/options' },
    { name: RouteName.VIEW_INSTRUMENT_FORWARDS, path: 'instruments/:securityId/forwards' },
    { name: RouteName.VIEW_INSTRUMENT_SWAPS, path: 'instruments/:securityId/swaps' },
    { name: RouteName.VIEW_INSTRUMENT_PARSING_LOG, path: 'instruments/:securityId/parsing-log' },
  ].map(({ name, path }) => ({
    name: name,
    path: path,
    components: {
      default: () => import('@/views/admin/instruments/AdminInspectSecurity.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Instruments',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  })),
  {
    name: RouteName.VIEW_FUTURE,
    path: 'instruments/futures/:specId',
    components: {
      default: () => import('@/views/admin/instruments/AdminFutures.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Instruments | Futures',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.TEAM_MANAGEMENT,
    path: 'teams',
    components: {
      default: () => import('@/views/admin/team/AdminTeams.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Teams',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.EDIT_TEAM,
    path: 'teams/:id',
    components: {
      default: () => import('@/views/admin/team/AdminTeamEdit.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Edit Team',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.FILE_MANAGEMENT,
    path: 'files',
    components: {
      default: () => import('@/views/admin/files/index/AdminFiles.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Files',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.VIEW_FILE,
    path: 'files/:fileContributionId',
    components: {
      default: () => import('@/views/admin/files/inspect/AdminInspectFile.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Files | Inspect File',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.CONSTITUENT_MANAGEMENT,
    path: 'constituents',
    components: {
      default: () => import('@/views/admin/constituents/index/AdminConstituents.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Constituent',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.PROXY_CHECK,
    path: 'constituents/proxy-check',
    components: {
      default: () => import('@/views/admin/constituents/proxy-check/AdminConstituentsProxyCheck.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Constituent | Proxy Check',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.VIEW_CONSTITUENT,
    path: 'constituents/:id',
    components: {
      default: () => import('@/views/admin/constituents/overview/AdminConstituentInspect.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Constituent',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.VIEW_FILES,
    path: 'constituents/:id/files',
    components: {
      default: () => import('@/views/admin/constituents/files/AdminConstituentInspectFiles.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Constituent Files',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.VIEW_POSITIONS,
    path: 'constituents/:id/position',
    components: {
      default: () => import('@/views/admin/constituents/position/AdminConstituentInspectPosition.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Constituent Positions',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.VIEW_POSITION,
    path: 'constituents/:id/position/:date',
    components: {
      default: () => import('@/views/admin/constituents/position/AdminConstituentInspectPosition.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Constituent Position',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.RISK_MANAGEMENT,
    path: 'risk',
    redirect: { name: RouteName.RISK_MANAGEMENT_RISK_PORTFOLIOS },
  },
  {
    name: RouteName.RISK_MANAGEMENT_RISK_PORTFOLIOS,
    path: 'risk/portfolios',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/risk/AdminRisk.vue'),
      default: () => import('@/views/admin/risk/AdminRiskPortfolio.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Risk Portfolios',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.RISK_MANAGEMENT_RPP_JOB_STATUS,
    path: 'risk/rpp-job-status',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/risk/AdminRisk.vue'),
      default: () => import('@/views/admin/risk/AdminRiskJobStatus.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | RPP Job Status',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.API_KEYS,
    path: 'keys',
    components: {
      default: () => import('@/views/admin/api-key/AdminApiKey.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | API Keys',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.SETTINGS,
    path: 'settings',
    components: {
      default: () => import('@/views/admin/setting/AdminSetting.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Settings',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.PEFORMANCE_CONTRIBUTION_CONFIG,
    path: 'perf-contribution',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/perf-contribution/AdminPerfContributionToolbar.vue'),
      default: () => import('@/views/admin/perf-contribution/AdminPerfContributionConfig.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Perf Contribution | Config',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.PEFORMANCE_CONTRIBUTION_BACKFILL,
    path: 'perf-contribution/backfill',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/perf-contribution/AdminPerfContributionToolbar.vue'),
      default: () => import('@/views/admin/perf-contribution/AdminPerfContributionBackfill.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Perf Contribution | Backfill',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.PEFORMANCE_CONTRIBUTION_GENERATED_DATA,
    path: 'perf-contribution/generated-data',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/perf-contribution/AdminPerfContributionToolbar.vue'),
      default: () => import('@/views/admin/perf-contribution/AdminPerfContributionGeneratedData.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Perf Contribution | Generated Data',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.PEFORMANCE_CONTRIBUTION_JOB_STATUS,
    path: 'perf-contribution/job-status',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/perf-contribution/AdminPerfContributionToolbar.vue'),
      default: () => import('@/views/admin/perf-contribution/AdminPerfContributionJobStatus.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Perf Contribution | Job Status',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.EDIT_PERFORMANCE_CONTRIBUTION,
    path: 'perf-contribution/:id',
    components: {
      default: () => import('@/views/admin/perf-contribution/AdminPerfContributionConfigForm.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Edit Perf Contribution',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.CREATE_PERFORMANCE_CONTRIBUTION_CONFIG,
    path: 'perf-contribution-create',
    components: {
      default: () => import('@/views/admin/perf-contribution/AdminPerfContributionConfigForm.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Create Perf Contribution',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.QA,
    path: 'qa',
    redirect: { name: RouteName.TRACK_QA },
  },
  {
    name: RouteName.TRACK_QA,
    path: 'qa/track',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/qa/AdminQA.vue'),
      default: () => import('@/views/admin/qa/AdminTrackQA.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Track QA',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.CONSTITUENT_QA,
    path: 'qa/constituent',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/qa/AdminQA.vue'),
      default: () => import('@/views/admin/qa/AdminConstituentQA.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Constituent QA',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.VIEW_TRACK_QA,
    path: 'qa/track/:workflowId/:runId/track',
    components: {
      default: () => import('@/views/admin/qa/AdminTrackQAInspect.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Track QA',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.VIEW_CONSTITUENT_QA,
    path: 'qa/constituent/:workflowId/:runId/track',
    components: {
      default: () => import('@/views/admin/qa/AdminConstituentQAInspect.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Constituent QA',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.REPORT_FIELDS,
    path: 'report-fields',
    components: {
      default: () => import('@/views/admin/report-fields/AdminReportFields.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Report Fields',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.VIEW_REPORT_FIELD,
    path: 'report-fields/:fieldId',
    components: {
      default: () => import('@/views/admin/report-fields/AdminReportFieldInspect.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Report Field',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.EXCEL_JOBS,
    path: 'jobs/excel',
    components: {
      default: () => import('@/views/admin/jobs/AdminExcelJobs.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Excel Jobs',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.CONTENT_MANAGEMENT,
    path: 'articles',
    components: {
      default: () => import('@/views/admin/articles/AdminContentManagement.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Content Management',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.MIGRATIONS_ADMIN,
    path: 'migrations',
    components: {
      default: () => import('@/views/admin/migrations/AdminMigrations.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Migrations',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.INSPECT_MIGRATION,
    path: 'migrations/:applicationName',
    components: {
      default: () => import('@/views/admin/migrations/AdminInspectMigration.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Migration',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.MUTATIONS_ADMIN,
    path: 'mutations',
    components: {
      default: () => import('@/views/admin/migrations/AdminMutations.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Mutations',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.INSPECT_MUTATION,
    path: 'mutations/:createdAt/:applicationName',
    components: {
      default: () => import('@/views/admin/migrations/AdminInspectMutation.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Mutation',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
];

export const AdminRouter: RouteConfig[] = [
  {
    path: '/console',
    name: RouteName.CONSOLE,
    components: {
      default: BofAAppMain,
    },
    redirect: { name: RouteName.TRACK_MANAGEMENT },
    meta: {
      title: 'Admin',
      permission: [UserPermission.ADMIN],
    },
    children: adminChildrenRouter,
  },
];
